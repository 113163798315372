import React, { useEffect, useState } from 'react';
import apiService from '../../services/apiService';
import { Link } from 'react-router-dom';

const FPRList = () => {
  const [fprList, setFprList] = useState([]);

  useEffect(() => {
    const fetchFPRs = async () => {
      try {
        const response = await apiService.getAllFPR();
        // Pastikan response.data adalah array
        if (Array.isArray(response)) {
          setFprList(response);
        } else {
          console.error('Expected response.data to be an array:', response);
        }
      } catch (error) {
        console.error('Error fetching FPRs:', error);
      }
    };

    fetchFPRs();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this request?')) {
      try {
        await apiService.deleteFPRById(id);
        setFprList(fprList.filter((fpr) => fpr.id !== id));
      } catch (error) {
        console.error('Error deleting FPR:', error);
      }
    }
  };

  // Fungsi untuk menghitung waktu mundur atau waktu yang telah berlalu
  const getTimeRemaining = (expiryDate) => {
    const now = new Date();
    const expiry = new Date(expiryDate);
    const distance = expiry - now;

    if (distance > 0) {
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      return `Expire ${minutes} menit ${seconds} detik lagi`;
    } else {
      const elapsed = Math.abs(distance);
      const days = Math.floor(elapsed / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (elapsed % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((elapsed % (1000 * 60 * 60)) / (1000 * 60));
      const years = Math.floor(days / 365);
      const weeks = Math.floor(days / 7);

      if (years > 0) {
        return `Expired ${years} tahun yang lalu`;
      } else if (weeks > 0) {
        return `Expired ${weeks} minggu yang lalu`;
      } else if (days > 0) {
        return `Expired ${days} hari yang lalu`;
      } else if (hours > 0) {
        return `Expired ${hours} jam yang lalu`;
      } else {
        return `Expired ${minutes} menit yang lalu`;
      }
    }
  };

  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4">Forget Password Requests</h2>
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>ID</th>
              <th>Email</th>
              <th>Reset Code</th>
              <th>Is Used</th>
              <th>Expiry</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {fprList.length > 0 ? (
              fprList.map((fpr) => (
                <tr key={fpr.id}>
                  <td>{fpr.id}</td>
                  <td>{fpr.email}</td>
                  <td>{fpr.resetCode}</td>
                  <td>{fpr.isUsed ? 'Yes' : 'No'}</td>
                  <td>{getTimeRemaining(fpr.resetCodeExpiry)}</td>
                  <td>{new Date(fpr.createdAt).toLocaleString()}</td>
                  <td>
                    <Link
                      to={`/admin/fpr/edit/${fpr.id}`}
                      className="btn btn-primary btn-sm me-2"
                    >
                      Edit
                    </Link>
                    <button
                      onClick={() => handleDelete(fpr.id)}
                      className="btn btn-danger btn-sm"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center">
                  No Forget Password Requests found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FPRList;
