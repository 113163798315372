import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  const [greeting, setGreeting] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);

  // Fungsi untuk mendapatkan salam
  const getGreeting = useCallback(() => {
    const hours = new Date().getHours();
    if (hours < 12) return 'Selamat Pagi';
    if (hours < 18) return 'Selamat Siang';
    return 'Selamat Malam';
  }, []);

  // Cek status login saat komponen dimuat
  useEffect(() => {
    const tokenDataString = localStorage.getItem('tokenData');

    if (tokenDataString) {
      try {
        const tokenData = JSON.parse(tokenDataString);
        const TOKEN_EXPIRATION = 24 * 60 * 60 * 1000; // 24 jam

        // Periksa apakah token masih berlaku
        if (Date.now() - tokenData.createdAt <= TOKEN_EXPIRATION) {
          setIsLoggedIn(true);
          setUserData(tokenData.user);
        } else {
          // Token expired
          localStorage.removeItem('tokenData');
          setIsLoggedIn(false);
        }
      } catch (error) {
        localStorage.removeItem('tokenData');
        setIsLoggedIn(false);
      }
    }
  }, []);

  // Effect untuk inisialisasi
  useEffect(() => {
    document.title = 'Beranda - Ujian Tool';
    setGreeting(getGreeting());
  }, [getGreeting]);

  // Fungsi logout dengan useCallback
  const handleLogout = useCallback(() => {
    localStorage.removeItem('tokenData');
    setIsLoggedIn(false);
    setUserData(null);
    navigate('/');
  }, [navigate]);

  // Fungsi login dengan useCallback
  const handleLogin = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  // Memoize tombol-tombol untuk mengurangi re-render
  const actionButtons = useMemo(() => {
    return (
      <div className="d-flex flex-column flex-sm-row justify-content-center gap-3 w-100">
        <Link
          to="/cari-nilai"
          className="btn btn-primary btn-lg d-flex align-items-center justify-content-center flex-grow-1"
        >
          <i className="fas fa-search me-2"></i>
          Cari Nilai
        </Link>

        <Link
          to="/soal"
          className="btn btn-outline-primary btn-lg d-flex align-items-center justify-content-center flex-grow-1"
        >
          <i className="fas fa-file-alt me-2"></i>
          Lihat Soal
        </Link>

        {/* Tambahkan tombol Admin Panel jika pengguna adalah admin */}
        {isLoggedIn && userData && userData.isAdmin && (
          <Link
            to="/admin"
            className="btn btn-warning btn-lg d-flex align-items-center justify-content-center flex-grow-1"
          >
            <i className="fas fa-user-shield me-2"></i>
            Admin Panel
          </Link>
        )}
      </div>
    );
  }, [isLoggedIn, userData]);

  // Tombol login/logout dan settings
  const authButton = useMemo(() => {
    return (
      <div className="d-flex flex-column gap-3">
        {isLoggedIn && (
          <Link
            to="/user"
            className="btn btn-outline-primary btn-lg d-flex align-items-center justify-content-center w-100"
          >
            <i className="fas fa-user me-2"></i>
            Profil Saya
          </Link>
        )}

        {isLoggedIn ? (
          <button
            onClick={handleLogout}
            className="btn btn-danger btn-lg d-flex align-items-center justify-content-center w-100"
          >
            <i className="fas fa-sign-out-alt me-2"></i>
            Logout
          </button>
        ) : (
          <button
            onClick={handleLogin}
            className="btn btn-success btn-lg d-flex align-items-center justify-content-center w-100"
          >
            <i className="fas fa-sign-in-alt me-2"></i>
            Login
          </button>
        )}
      </div>
    );
  }, [isLoggedIn, handleLogin, handleLogout]);

  return (
    <div
      className="container-fluid min-vh-100 d-flex align-items-center justify-content-center bg-gradient"
      style={{
        background: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)',
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 col-xl-6">
            <div
              className="card shadow-lg border-0 rounded-4 overflow-hidden"
              style={{
                background: 'rgba(255,255,255,0.9)',
                backdropFilter: 'blur(10px)',
              }}
            >
              <div className="card-body p-4 p-md-5 text-center">
                <div className="mb-4">
                  <h1 className="display-5 fw-bold text-primary mb-3">
                    Alat Ujian v5.2
                  </h1>
                  <h2 className="h3 text-muted mb-4">
                    {greeting}
                    {isLoggedIn && userData ? `, ${userData.username}` : ''}
                  </h2>

                  <p className="lead text-muted mb-5">
                    Temukan Informasi Soal dan Nilai Anda dengan Mudah
                  </p>
                </div>

                <div className="d-flex flex-column align-items-stretch">
                  {isLoggedIn && <div className="mb-3">{actionButtons}</div>}

                  {authButton}
                </div>
              </div>

              <div className="card-footer text-center bg-light py-3">
                <small className="text-muted">
                  Dibuat dengan ❤️ oleh zyqs.fa dalam 18 jam
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
