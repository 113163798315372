import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router';
import { initializeApp } from 'firebase/app';
import ScrollToTopButton from './ScrollToTopButton';
import firebaseConfig from '../firebaseConfig';
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'bootstrap';

// Inisialisasi Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const DetailJawaban = () => {
  const { mapel, userId } = useParams();
  const [userData, setUserData] = useState(null);
  const [soalData, setSoalData] = useState(null);
  const [jawabanData, setJawabanData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nilaiData, setNilaiData] = useState({
    totalNilai: 0,
    detailNilai: {},
  });
  const [dataKunci, setDataKunci] = useState({});

  const navigate = useNavigate();

  // Fungsi untuk parsing jawaban (menggunakan useCallback)
  const parseJawaban = useCallback((jawaban) => {
    try {
      // Pastikan input adalah string sebelum parsing
      const jawabanObj =
        typeof jawaban === 'string' ? JSON.parse(jawaban) : jawaban;

      // Hapus properti yang tidak diperlukan
      const { id, server, ...cleanJawaban } = jawabanObj;
      return cleanJawaban;
    } catch (error) {
      return {};
    }
  }, []);

  // Fetch data kunci dengan error handling yang lebih baik
  useEffect(() => {
    const fetchDataKunci = async () => {
      try {
        const kunciRef = doc(db, '494/datakunci/datakunci', mapel);
        const kunciSnap = await getDoc(kunciRef);

        if (kunciSnap.exists()) {
          const kunciData = kunciSnap.data();
          setDataKunci({
            kunci: kunciData.kunci || {},
            bobot: kunciData.bobot || {},
          });
        } else {
        }
      } catch (error) {
        setError(`Gagal mengambil data kunci: ${error.message}`);
      }
    };

    if (mapel) {
      fetchDataKunci();
    }
  }, [mapel]);

  // Fetch data soal dan jawaban dengan validasi lebih ketat
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Query untuk mencari data siswa
        const userCollection = collection(db, '494/datasiswa/datasiswa');
        const userQuery = query(
          userCollection,
          where('user', '==', userId),
          where('mapel', '==', mapel)
        );

        const userSnapshot = await getDocs(userQuery);

        if (userSnapshot.empty) {
          throw new Error('Data pengguna tidak ditemukan');
        }

        const userData = userSnapshot.docs[0].data();

        // Ambil data soal
        const soalDocRef = doc(db, '494/soal/soal', mapel);
        const soalSnapshot = await getDoc(soalDocRef);

        if (!soalSnapshot.exists()) {
          throw new Error('Soal tidak ditemukan');
        }

        const soalData = soalSnapshot.data();
        const soalArray = Array.isArray(soalData.soal) ? soalData.soal : [];
        const parsedJawaban = parseJawaban(userData.jawaban);

        // Set state dengan data yang diambil
        setUserData(userData);
        setSoalData({ ...soalData, soal: soalArray });
        setJawabanData(parsedJawaban);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    // Pastikan userId dan mapel tersedia sebelum fetch
    if (userId && mapel) {
      fetchData();
    }
  }, [mapel, userId, parseJawaban]);

  // Hitung nilai dengan useMemo untuk optimasi
  const calculatedNilaiData = useMemo(() => {
    if (!jawabanData || Object.keys(dataKunci.kunci).length === 0) {
      return { totalNilai: 0, detailNilai: {} };
    }

    let totalNilai = 0;
    const detailNilai = {};

    Object.keys(jawabanData).forEach((noasli) => {
      const kunciItem = dataKunci.kunci[noasli];
      const bobotItem = dataKunci.bobot[noasli];

      if (kunciItem) {
        const benar = jawabanData[noasli] === kunciItem;
        const nilaiSoal = benar ? bobotItem : 0;

        detailNilai[noasli] = {
          jawaban: jawabanData[noasli],
          kunci: kunciItem,
          bobot: bobotItem,
          benar: benar,
        };

        totalNilai += nilaiSoal;
      }
    });

    return { totalNilai, detailNilai };
  }, [jawabanData, dataKunci]);

  // Gunakan effect untuk update nilaiData
  useEffect(() => {
    setNilaiData(calculatedNilaiData);
  }, [calculatedNilaiData]);

  // Render loading
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  // Render error
  if (error) {
    return (
      <div className="container mt-5">
        <div className="alert alert-danger">
          <h4>Terjadi Kesalahan</h4>
          <p>{error}</p>
          <button
            onClick={() => navigate(-1)}
            className="btn btn-primary w-100 rounded-pill mt-2"
          >
            <i className="fas fa-arrow-left me-2"></i>
            Kembali
          </button>
        </div>
      </div>
    );
  }

  // Render jika tidak ada soal
  if (
    !soalData ||
    !Array.isArray(soalData.soal) ||
    soalData.soal.length === 0
  ) {
    return (
      <div className="container mt-5">
        <div className="alert alert-warning">
          Tidak ada soal yang tersedia.
          <Button
            onClick={() => navigate(-1)}
            className="btn btn-primary mt-3 ms-2"
          >
            Kembali
          </Button>
        </div>
      </div>
    );
  }

  // Render detail jawaban (sisanya tetap sama seperti sebelumnya)
  return (
    <div className="container-fluid px-3 px-md-4 py-4">
      {/* Header Informasi Siswa */}
      <div className="card mb-4 shadow-sm">
        <div className="card-header bg-primary text-white d-flex align-items-center">
          <button
            onClick={() => navigate(-1)}
            className="btn btn-light me-2 me-md-3"
          >
            <i className="fas fa-arrow-left"></i>
          </button>
          <h2 className="mb-0 flex-grow-1 fs-5 fs-md-4">
            Detail Jawaban: {mapel}
          </h2>
        </div>
        <div className="card-body">
          {/* Informasi Siswa */}
          <div className="row g-2 g-md-3">
            {[
              {
                icon: 'user',
                label: 'Nama',
                value: userData.nama || 'Tidak ada nama',
              },
              {
                icon: 'graduation-cap',
                label: 'Kelas',
                value: userData.nik || 'Tidak tersedia',
              },
              {
                icon: 'door-open',
                label: 'Ruang Ujian',
                value: userData.nik2 || 'Tidak tersedia',
              },
              {
                icon: 'chart-line',
                label: 'Nilai',
                value: nilaiData.totalNilai.toFixed(2) || 'Tidak tersedia',
              },
            ].map((item, index) => (
              <div key={index} className="col-6 col-md-3">
                <div className="bg-light p-2 p-md-3 rounded h-100">
                  <div className="d-flex align-items-center mb-1">
                    <i className={`fas fa-${item.icon} me-2 text-primary`}></i>
                    <strong className="small">{item.label}</strong>
                  </div>
                  <p className="mb-0 text-muted small text-truncate">
                    {item.value}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* Waktu Selesai dan Sisa Waktu */}
          <div className="row g-2 g-md-3 mt-2">
            {[
              {
                icon: 'clock',
                label: 'Waktu Selesai',
                value: userData.waktuSelesai
                  ? new Date(userData.waktuSelesai).toLocaleString('id-ID', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                      timeZone: 'Asia/Jakarta',
                    })
                  : 'Tidak tersedia',
              },
              {
                icon: 'hourglass-half',
                label: 'Sisa Waktu',
                value:
                  userData.sisawaktu !== undefined
                    ? `${userData.sisawaktu} Menit`
                    : 'Tidak tersedia',
              },
            ].map((item, index) => (
              <div key={index} className="col-12 col-md-6">
                <div className="bg-light p-2 p-md-3 rounded">
                  <div className="d-flex align-items-center mb-1">
                    <i className={`fas fa-${item.icon} me-2 text-primary`}></i>
                    <strong className="small">{item.label}</strong>
                  </div>
                  <p className="mb-0 text-muted small">{item.value}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Soal dan Jawaban */}
      {soalData.soal.map((item) => {
        const jawabanStatus = nilaiData.detailNilai[item.noasli];

        return (
          <div
            key={item.noasli}
            className={`card mb-3 shadow-sm ${
              jawabanStatus
                ? jawabanStatus.benar
                  ? 'border-success border-3'
                  : 'border-danger border-3'
                : ''
            }`}
          >
            <div className="card-body">
              <h5 className="mb-3 d-flex justify-content-between align-items-center">
                Soal No {item.noasli}
                {jawabanStatus && (
                  <div className="d-flex align-items-center">
                    {jawabanStatus.benar ? (
                      <span className="badge bg-success me-2">
                        <i className="fas fa-check-circle me-1"></i>Benar
                      </span>
                    ) : (
                      <span className="badge bg-danger me-2">
                        <i className="fas fa-times-circle me-1"></i>Salah
                      </span>
                    )}
                    {jawabanStatus.bobot && (
                      <span className="badge bg-info">
                        Nilai: {jawabanStatus.bobot}
                      </span>
                    )}
                  </div>
                )}
              </h5>
              <div
                className="mb-3 small"
                dangerouslySetInnerHTML={{ __html: item.soal || '' }}
              />

              <div className="row g-2">
                {(item.options || []).map((option) => {
                  const dipilih =
                    jawabanData &&
                    jawabanData[item.noasli] === option.optionasli;

                  let className = 'p-2 border rounded position-relative';

                  if (dipilih) {
                    if (jawabanStatus && jawabanStatus.benar) {
                      className += ' bg-success text-white';
                    } else if (jawabanStatus && !jawabanStatus.benar) {
                      className += ' bg-danger text-white';
                    } else {
                      className += ' bg-primary text-white';
                    }
                  } else {
                    className += ' bg-light';
                  }

                  if (
                    jawabanStatus &&
                    option.optionasli === jawabanStatus.kunci
                  ) {
                    className += ' border-success border-3';
                  }

                  return (
                    <div
                      key={option.optionasli}
                      className="col-12 col-md-6 position-relative"
                    >
                      <div className={className}>
                        <strong className="d-block mb-1">
                          {option.optionasli}:
                        </strong>
                        <div
                          className="small"
                          dangerouslySetInnerHTML={{
                            __html: option.answer || '',
                          }}
                        />

                        {jawabanStatus && dipilih && (
                          <div
                            className="position-absolute top-0 end-0 p-1"
                            style={{ fontSize: '1.5rem' }}
                          >
                            {jawabanStatus.benar ? (
                              <i className="fas fa -check-circle text-success"></i>
                            ) : (
                              <i className="fas fa-times-circle text-danger"></i>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              {jawabanStatus && !jawabanStatus.benar && (
                <div className="alert alert-danger mt-3" role="alert">
                  <strong>Penjelasan:</strong>
                  <p className="mb-0">
                    Jawaban yang benar adalah pilihan {jawabanStatus.kunci}.
                  </p>
                </div>
              )}
            </div>
          </div>
        );
      })}

      <ScrollToTopButton />
    </div>
  );
};

export default React.memo(DetailJawaban);
