import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router'; // Perbaiki impor router
import { initializeApp } from 'firebase/app';
import firebaseConfig from '../firebaseConfig.js';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/SoalDetail.css';

// Inisialisasi Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const SoalDetail = () => {
  const { slug } = useParams();
  const [soalData, setSoalData] = useState(null);
  const [kunciJawaban, setKunciJawaban] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch Soal
        const soalDoc = doc(db, '494/soal/soal', slug);
        const soalSnapshot = await getDoc(soalDoc);

        // Fetch Kunci Jawaban
        const kunciDoc = doc(db, '494/datakunci/datakunci', slug);
        const kunciSnapshot = await getDoc(kunciDoc);

        if (soalSnapshot.exists() && kunciSnapshot.exists()) {
          const soalData = soalSnapshot.data();
          const kunciData = kunciSnapshot.data().kunci;

          setSoalData(soalData);
          setKunciJawaban(kunciData);
        } else {
          setSoalData(undefined);
          setKunciJawaban(undefined);
        }
      } catch (error) {
      } finally {
        setLoading(false);
        document.title = 'Detail Soal - Ujian Tool';
      }
    };

    fetchData();
  }, [slug]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (loading) {
    return <div className="alert alert-info">Loading...</div>;
  }

  if (!soalData || !kunciJawaban) {
    return (
      <div className="alert alert-danger mt-5" role="alert">
        ERROR: DATA TIDAK DITEMUKAN
        <Link to="/soal" className="btn btn-primary mt-3">
          Kembali
        </Link>
      </div>
    );
  }

  return (
    <div className="container-fluid px-3 px-md-4 py-4">
      <div className="card mb-4 shadow-sm">
        <div className="card-header bg-primary text-white d-flex align-items-center">
          <Link to="/soal" className="btn btn-light me-2 me-md-3">
            <i className="fas fa-arrow-left"></i>
          </Link>
          <div className="d-flex align-items-center justify-content-between w-100">
            <h2 className="mb-0 flex-grow-1 fs-5 fs-md-4">
              Detail Soal ID: {slug}
            </h2>
            <span className="badge bg-white text-primary border border-primary">
              <i className="fas fa-list-ol me-1"></i>
              Jumlah Soal: {soalData.soal.length}
            </span>
          </div>
        </div>
      </div>

      {soalData.soal.map((item, index) => (
        <div
          key={index}
          id={`soalno${item.noasli}`}
          className="card mb-3 shadow-sm"
        >
          <div className="card-body">
            <div className="d-flex align-items-center mb-3">
              <h5 className="mb-0 me-3">Soal No {item.noasli}</h5>
              {item.imageUrl && (
                <div className="ms-auto">
                  <ImageWithFallback
                    src={item.imageUrl}
                    alt={`Gambar Soal ${item.noasli}`}
                    className="img-fluid rounded"
                    style={{ maxHeight: '100px', maxWidth: '150px' }}
                  />
                </div>
              )}
            </div>

            {item.soal ? (
              <>
                <div
                  className="card-text mb-3 small"
                  dangerouslySetInnerHTML={{ __html: item.soal }}
                />

                <h6 className="mt-3 mb-3">Pilihan:</h6>
                <div className="row g-2">
                  {item.options.map((option, idx) => {
                    const isKunciJawaban =
                      kunciJawaban[item.noasli] === option.optionasli;

                    return (
                      <div key={idx} className="col-12 col-md-6">
                        <div
                          className={`card h-100 ${isKunciJawaban ? 'border-success' : ''}`}
                          style={{
                            borderWidth: isKunciJawaban ? '2px' : '1px',
                            position: 'relative', // Tambahkan positioning
                          }}
                        >
                          {isKunciJawaban && (
                            <div
                              className="position-absolute top-0 end-0 p-2"
                              style={{
                                zIndex: 10,
                              }}
                            >
                              <span
                                className="badge bg-success rounded-circle shadow"
                                title="Jawaban Benar"
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <i className="fas fa-check"></i>
                              </span>
                            </div>
                          )}
                          <div className="card-body">
                            <div className="d-flex align-items-start mb-2">
                              <strong
                                className={`me-2 ${isKunciJawaban ? 'text-success' : ''}`}
                              >
                                {option.optionasli}:
                              </strong>
                              {option.imageUrl && (
                                <ImageWithFallback
                                  src={option.imageUrl}
                                  alt={`Pilihan ${option.optionasli}`}
                                  className="img-fluid rounded ms-auto"
                                  style={{
                                    maxHeight: '100px',
                                    maxWidth: '150px',
                                  }}
                                />
                              )}
                            </div>
                            <div
                              className="small"
                              dangerouslySetInnerHTML={{
                                __html: option.answer,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <div className="alert alert-danger">
                ERROR: DATA TIDAK DITEMUKAN
              </div>
            )}
          </div>
        </div>
      ))}

      {showScroll && (
        <button
          className="btn btn-primary back-to-top shadow"
          onClick={scrollToTop}
          style={{
            position: 'fixed',
            bottom: '15px',
            right: '15px',
            borderRadius: '50%',
            width: '45px',
            height: '45px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
            transition: 'all 0.3s ease',
          }}
          aria-label="Scroll to Top"
        >
          <i className="fas fa-chevron-up"></i>
        </button>
      )}
    </div>
  );
};

const ImageWithFallback = ({ src, alt }) => {
  const [showImage, setShowImage] = useState(true);

  const handleError = () => {
    setShowImage(false);
    if (process.env.NODE_ENV === 'development') {
    }
  };

  const isValidUrl = (url) => {
    try {
      return url && new URL(url);
    } catch {
      return false;
    }
  };

  if (!src || !showImage || !isValidUrl(src)) {
    return null;
  }

  return (
    <div className="media-container">
      <img
        src={src} // Langsung gunakan src
        alt={alt || 'Gambar'}
        onError={handleError}
        className="responsive-image"
        loading="lazy"
        style={{
          maxWidth: '100%',
          height: 'auto',
          objectFit: 'contain',
        }}
      />
    </div>
  );
};

export default SoalDetail;
