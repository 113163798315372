import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiService from '../services/apiService';

const UserPage = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await apiService.getUserProfile();
        setUserData(data);
        setLoading(false);
      } catch (error) {
        toast.error('Gagal memuat data pengguna');
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleLogout = () => {
    try {
      apiService.logout();
      navigate('/login');
      toast.success('Berhasil logout');
    } catch (error) {
      toast.error('Gagal logout');
    }
  };

  if (loading) {
    return (
      <div className="container-fluid vh-100 d-flex align-items-center justify-content-center bg-light">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid vh-100 d-flex align-items-center justify-content-center bg-light">
      <div className="row w-100 justify-content-center">
        <div className="col-12 col-md-8 col-lg-5">
          <div
            className="card shadow-lg border-0 rounded-4 p-4 p-md-5"
            style={{
              background: 'linear-gradient(135deg, #f6f8f9 0%, #e5ebee 100%)',
              transform: 'perspective(1000px) rotateX(2deg)',
            }}
          >
            <div className="position-absolute top-0 start-0 m-3">
              <button
                onClick={() => navigate(-1)}
                className="btn btn-light me-2 me-md-3"
              >
                <i className="fas fa-arrow-left"></i>
              </button>
            </div>
            <div className="card-body text-center">
              <div className="mb-4">
                <h2 className="card-title text-primary">
                  {userData.firstName} {userData.lastName}
                </h2>
                <p className="text-muted">@{userData.username}</p>
              </div>

              <div className="user-details mb-4">
                <div className="detail-item mb-2">
                  <i className="fas fa-envelope me-2 text-muted"></i>
                  <span>{userData.email}</span>
                </div>
                <div className="detail-item">
                  <i className="fas fa-calendar me-2 text-muted"></i>
                  <span>
                    Bergabung:{' '}
                    {new Date(userData.createdAt).toLocaleDateString()}
                  </span>
                </div>
              </div>

              <div className="d-grid gap-2">
                <button
                  className="btn btn-primary btn-lg"
                  onClick={() => navigate('/profile')}
                >
                  <i className="fas fa-user me-2"></i>
                  Edit Profil
                </button>
                <button
                  className="btn btn-outline-danger btn-lg"
                  onClick={handleLogout}
                >
                  <i className="fas fa-sign-out-alt me-2"></i>
                  Logout
                </button>
              </div>
            </div>

            <p
              className="text-secondary text-center mt-4"
              style={{
                fontSize: '0.9rem',
                fontStyle: 'italic',
              }}
            >
              Dibuat dengan ❤️ oleh zyqs.fa
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPage;
