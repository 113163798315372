import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import apiService from '../../services/apiService';

const FPREdit = () => {
  const { resetId } = useParams();
  const navigate = useNavigate();
  const [fprData, setFprData] = useState({
    resetCode: '',
    email: '',
    resetCodeExpiry: '',
    isUsed: false,
  });

  useEffect(() => {
    const fetchFPR = async () => {
      try {
        const response = await apiService.getFPRById(resetId);
        setFprData(response); // Menggunakan response langsung karena strukturnya sudah sesuai
      } catch (error) {
        console.error('Error fetching FPR:', error);
      }
    };

    fetchFPR();
  }, [resetId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFprData({ ...fprData, [name]: value });
  };

  const handleExpiryChange = (e) => {
    const minutesToAdd = parseInt(e.target.value, 10);
    if (!isNaN(minutesToAdd)) {
      const newExpiryDate = new Date(fprData.resetCodeExpiry);
      newExpiryDate.setMinutes(newExpiryDate.getMinutes() + minutesToAdd);
      setFprData({ ...fprData, resetCodeExpiry: newExpiryDate.toISOString() });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await apiService.editFPRById(resetId, fprData);
      navigate('/admin/fpr'); // Kembali ke daftar FPR setelah berhasil
    } catch (error) {
      console.error('Error updating FPR:', error);
    }
  };

  return (
    <div>
      <h2>Edit Forget Password Request</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="resetCode" className="form-label">
            Reset Code
          </label>
          <input
            type="text"
            className="form-control"
            id="resetCode"
            name="resetCode"
            value={fprData.resetCode || ''}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={fprData.email || ''}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="resetCodeExpiry" className="form-label">
            Reset Code Expiry (in minutes)
          </label>
          <input
            type="number"
            className="form-control"
            id="resetCodeExpiry"
            name="resetCodeExpiry"
            onChange={handleExpiryChange}
            placeholder="Add minutes (max 1440)"
            min="1"
            max="1440"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="isUsed" className="form-label">
            Is Used
          </label>
          <select
            className="form-control"
            id="isUsed"
            name="isUsed"
            value={fprData.isUsed ? 'true' : 'false'}
            onChange={handleChange}
          >
            <option value="false">No</option>
            <option value="true">Yes</option>
          </select>
        </div>
        <button type="submit" className="btn btn-primary">
          Update
        </button>
      </form>
    </div>
  );
};

export default FPREdit;
