// components/APIStatus.js

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import apiService from '../services/apiService';
import { useNavigate } from 'react-router-dom'; // Ganti useHistory dengan useNavigate

const APIStatus = () => {
  const [statusData, setStatusData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Hook untuk navigasi

  useEffect(() => {
    const fetchAPIStatus = async () => {
      try {
        const data = await apiService.getAPIStatus();
        setStatusData(data);
      } catch (error) {
        toast.error('Gagal mengambil status API');
      } finally {
        setLoading(false);
      }
    };

    fetchAPIStatus();
  }, []);

  if (loading) {
    return (
      <div className="container-fluid vh-100 d-flex align-items-center justify-content-center bg-light">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid min-vh-100 d-flex align-items-center justify-content-center bg-light py-4">
      <div
        className="card shadow-lg border-0 rounded-4 p-4"
        style={{ maxWidth: '600px', width: '100%' }}
      >
        <h1 className="h3 text-primary text-center mb-4">Status API</h1>
        <div className="mb-3">
          <strong>Status:</strong>{' '}
          <span className="text-success">{statusData.status}</span>
        </div>
        <div className="mb-3">
          <strong>Server:</strong> {statusData.server}
        </div>
        <div className="mb-3">
          <strong>Database:</strong> {statusData.database}
        </div>
        <div className="mb-3">
          <strong>Timestamp:</strong>{' '}
          {new Date(statusData.timestamp).toLocaleString()}
        </div>
        <div className="mb-3">
          <strong>Uptime:</strong> {statusData.uptime} seconds
        </div>
        <div className="mb-3">
          <strong>Environment:</strong> {statusData.environment}
        </div>
        <button
          className="btn btn-primary w-100 mt-4"
          onClick={() => navigate(-1)} // Navigasi kembali menggunakan navigate
        >
          Kembali
        </button>
      </div>
    </div>
  );
};

export default APIStatus;
