// Tambahkan di bagian import
import React, { useState, useEffect } from 'react';

// Komponen ScrollToTopButton
const ScrollToTopButton = () => {
  const [showScroll, setShowScroll] = useState(false);

  // Fungsi untuk scroll ke atas
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Hook untuk mengatur visibilitas tombol
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  // Render tombol scroll to top
  return showScroll ? (
    <button
      className="btn btn-primary back-to-top"
      onClick={scrollToTop}
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        transition: 'all 0.3s ease',
      }}
      title="Kembali ke Atas"
      aria-label="Scroll to top"
    >
      <i
        className="fas fa-chevron-up"
        style={{
          fontSize: '1.2rem',
        }}
      ></i>
    </button>
  ) : null;
};

export default ScrollToTopButton;
