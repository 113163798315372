import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiService from '../services/apiService';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // State untuk login
  const [loginData, setLoginData] = useState({
    username: '',
    password: '',
  });

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Efek untuk setup halaman dan penanganan kondisi khusus
  useEffect(() => {
    document.title = 'Login - Aplikasi';

    // Cek autentikasi
    const checkAuth = async () => {
      if (apiService.isAuthenticated()) {
        const redirectPath = location.state?.from || '/'; // Menggunakan state dari navigasi jika ada
        navigate(redirectPath, { replace: true });
      }
    };

    checkAuth();
  }, [navigate, location]);

  // Handler untuk perubahan input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handler untuk submit login
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validasi input
    if (!loginData.username || !loginData.password) {
      toast.error('Username dan password harus diisi');
      setLoading(false);
      return;
    }

    try {
      const response = await apiService.login(loginData);

      // Proses login sukses
      toast.success(`Selamat datang, ${response.user.firstName}!`);

      // Gunakan redirectPath yang diambil dari state navigasi
      const redirectPath = location.state?.from || '/';
      navigate(redirectPath, { replace: true });
    } catch (error) {
      let errorMessage = 'Login gagal';

      if (error.response) {
        switch (error.response.status) {
          case 401:
            errorMessage =
              error.response.data.message || 'Username atau password salah';
            break;
          case 403:
            errorMessage = 'Akun Anda diblokir';
            break;
          case 500:
            errorMessage = 'Kesalahan server. Silakan coba lagi nanti';
            break;
          default:
            errorMessage = error.response.data.message || 'Login gagal';
        }
      } else if (error.request) {
        errorMessage =
          'Tidak ada respons dari server. Periksa koneksi internet Anda.';
      } else {
        errorMessage =
          error.message || 'Terjadi kesalahan yang tidak diketahui';
      }

      // Tampilkan toast error
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid vh-100 d-flex align-items-center justify-content-center bg-light">
      <div className="row w-100 justify-content-center">
        <div className="col-12 col-md-8 col-lg-5">
          <div
            className="card shadow-lg border-0 rounded-4 p-4 p-md-5"
            style={{
              background: 'linear-gradient(135deg, #f6f8f9 0%, #e5ebee 100%)',
              transform: 'perspective(1000px) rotateX(2deg)',
            }}
          >
            <div className="card-body">
              <h1 className="display-5 fw-bold mb-4 text-primary text-center">
                Login Ujian
              </h1>

              <form onSubmit={handleLogin}>
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">
                    <i className="fas fa-user me-2 text-muted"></i>Username
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="username"
                    name="username"
                    value={loginData.username}
                    onChange={handleChange}
                    placeholder="Masukkan username"
                    required
                    disabled={loading}
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="password" className="form-label">
                    <i className="fas fa-lock me-2 text-muted"></i>Password
                  </label>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="form-control form-control-lg"
                    id="password"
                    name="password"
                    value={loginData.password}
                    onChange={handleChange}
                    placeholder="Masukkan password"
                    required
                    disabled={loading}
                  />
                  <div className="form-check mt-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="showPassword"
                      checked={showPassword}
                      onChange={() => setShowPassword(!showPassword)}
                    />
                    <label className="form-check-label" htmlFor="showPassword">
                      Tampilkan Password
                    </label>
                  </div>
                </div>

                <div className="d-grid">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg d-flex align-items-center justify-content-center"
                    disabled={loading}
                  >
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <i className="fas fa-sign-in-alt me-2"></i>
                    )}
                    {loading ? 'Memproses...' : 'Login'}
                  </button>

                  {/* Tombol/link kembali ke beranda dan reset password */}
                  <div className="mt-3 text-center d-flex justify-content-center">
                    <div className="me-3">
                      {' '}
                      {/* Add margin to the right for spacing */}
                      <Link to="/" className="btn btn-link">
                        Kembali ke Beranda
                      </Link>
                    </div>
                    <div>
                      <Link to="/reset-password" className="btn btn-link">
                        Lupa Password?
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <p
              className="text-secondary text-center mt-4"
              style={{
                fontSize: '0.9rem',
                fontStyle: 'italic',
              }}
            >
              Dibuat dengan ❤️ oleh zyqs.fa dalam 18 jam
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
