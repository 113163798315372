import React, { useEffect, useState } from 'react';
import apiService from '../../services/apiService';
import { FaUser, FaLock, FaHistory } from 'react-icons/fa';

const AdminDashboard = () => {
  const [fprCount, setFprCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [recentFPRs, setRecentFPRs] = useState([]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const fprResponse = await apiService.getAllFPR();
        const userResponse = await apiService.getAllUsers();

        setFprCount(fprResponse.length);
        setUserCount(userResponse.length);
        setRecentFPRs(fprResponse.slice(0, 5));
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchDashboardData();
  }, []);

  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4">Admin Dashboard</h2>
      <div className="row">
        <div className="col-md-4 mb-4">
          <div className="card text-white bg-primary shadow-sm">
            <div className="card-header d-flex align-items-center">
              <FaLock className="me-2" size={24} />
              Total Forget Password Requests
            </div>
            <div className="card-body">
              <h5 className="card-title display-4">{fprCount}</h5>
              <p className="card-text">
                Jumlah total permintaan reset password.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card text-white bg-success shadow-sm">
            <div className="card-header d-flex align-items-center">
              <FaUser className="me-2" size={24} />
              Total Users
            </div>
            <div className="card-body">
              <h5 className="card-title display-4">{userCount}</h5>
              <p className="card-text">Jumlah total pengguna terdaftar.</p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card text-white bg-warning shadow-sm">
            <div className="card-header d-flex align-items-center">
              <FaHistory className="me-2" size={24} />
              Recent Forget Password Requests
            </div>
            <div className="card-body">
              <ul className="list-group">
                {recentFPRs.length > 0 ? (
                  recentFPRs.map((fpr) => (
                    <li
                      key={fpr.id}
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <div className="flex-grow-1 me-2">
                        <span className="fw-bold">{fpr.email}</span>
                      </div>
                      <span className="text-muted small">
                        {new Date(fpr.createdAt).toLocaleString()}
                      </span>
                    </li>
                  ))
                ) : (
                  <li className="list-group-item">
                    Tidak ada permintaan terbaru.
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
