import React, { useEffect, useState, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import apiService from '../../services/apiService';
import { toast } from 'react-toastify';

const UserDetail = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newPassword, setNewPassword] = useState('');
  const [showPasswordForm, setShowPasswordForm] = useState(false);

  const fetchUserDetail = useCallback(async () => {
    try {
      const response = await apiService.getUserById(id);
      setUser(response);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchUserDetail();
  }, [fetchUserDetail]);

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    try {
      await apiService.changePasswordById(id, { newPassword: newPassword });
      toast.success('Password berhasil diperbarui');
      setNewPassword('');
      setShowPasswordForm(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (!user) {
    return <div className="text-center">Pengguna tidak ditemukan.</div>;
  }

  return (
    <div className="container mt-5">
      <h2 className="text-primary text-center mb-4">Detail Pengguna</h2>
      <div className="card shadow-sm">
        <div className="card-body">
          <div className="row mb-3">
            <div className="col-md-6">
              <strong>Username:</strong>
              <p className="text-muted">{user.username || '-'}</p>
            </div>
            <div className="col-md-6">
              <strong>Email:</strong>
              <p className="text-muted">{user.email || '-'}</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <strong>Nama Depan:</strong>
              <p className="text-muted">{user.firstName || '-'}</p>
            </div>
            <div className="col-md-6">
              <strong>Nama Belakang:</strong>
              <p className="text-muted">{user.lastName || '-'}</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <strong>Tanggal Dibuat:</strong>
              <p className="text-muted">
                {new Date(user.createdAt).toLocaleString() || '-'}
              </p>
            </div>
            <div className="col-md-6">
              <strong>Admin:</strong>
              <p className="text-muted">{user.isAdmin ? 'Ya' : 'Tidak'}</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <strong>Login Terakhir:</strong>
              <p className="text-muted">
                {user.lastLogin
                  ? new Date(user.lastLogin).toLocaleString()
                  : '-'}
              </p>
            </div>
            <div className="col-md-6">
              <strong>Diperbarui Pada:</strong>
              <p className="text-muted">
                {new Date(user.updatedAt).toLocaleString() || '-'}
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-4">
            <Link
              to={`/admin/users/edit/${user.id}`}
              className="btn btn-warning"
            >
              Edit Pengguna
            </Link>
            <Link to="/admin/users" className="btn btn-secondary">
              Kembali
            </Link>
            <button
              className="btn btn-info"
              onClick={() => setShowPasswordForm(!showPasswordForm)}
            >
              {showPasswordForm ? 'Batal Ganti Password' : 'Ganti Password'}
            </button>
          </div>
          {showPasswordForm && (
            <div className="mt-4">
              <h5 className="text-secondary">Ganti Password</h5>
              <form onSubmit={handlePasswordChange}>
                <div className="mb-3">
                  <label className="form-label">Password Baru</label>
                  <input
                    type="password"
                    className="form-control"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-success">
                  Perbarui Password
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
