// src/services/apiService.js
import axios from 'axios';

// Konfigurasi base URL dari environment variable
const BASE_URL = 'https://ap.azyqs.my.id/api'; // Ganti dengan URL server Anda

// Interceptor untuk menangani error secara global
const setupInterceptors = (navigate) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        // Error response dari server
        switch (error.response.status) {
          case 401:
            // Token expired atau unauthorized
            localStorage.removeItem('tokenData');
            navigate('/login', {
              state: {
                reason: 'unauthorized',
                message:
                  error.response.data.message || 'Sesi Anda telah berakhir',
              },
            });
            break;
          case 403:
            // Forbidden
            navigate('/login', {
              state: {
                reason: 'access_denied',
                message: 'Anda tidak memiliki izin akses',
              },
            });
            break;
          default:
            break;
        }
      }
      return Promise.reject(error);
    }
  );
};

// Fungsi untuk mendapatkan token dari localStorage
const getToken = () => {
  try {
    const tokenData = localStorage.getItem('tokenData');
    if (!tokenData) return null;

    const parsedData = JSON.parse(tokenData);

    // Cek apakah token sudah expired
    const TOKEN_EXPIRATION = 24 * 60 * 60 * 1000; // 24 jam
    if (Date.now() - parsedData.createdAt > TOKEN_EXPIRATION) {
      localStorage.removeItem('tokenData');
      return null;
    }

    return parsedData.token;
  } catch (error) {
    localStorage.removeItem('tokenData');
    return null;
  }
};

// Fungsi wrapper untuk request dengan error handling yang lebih baik
const makeRequest = async (requestFn) => {
  try {
    const response = await requestFn();
    return response.data;
  } catch (error) {
    // Tangani error spesifik dari backend
    if (error.response) {
      throw new Error(
        error.response.data.message ||
          'Terjadi kesalahan dalam memproses permintaan'
      );
    }

    // Error jaringan atau lainnya
    throw new Error(
      'Gagal terhubung ke server. Periksa koneksi internet Anda.'
    );
  }
};

const apiService = {
  // Inisialisasi interceptors
  init: (navigate) => {
    setupInterceptors(navigate);
  },

  // Cek status server
  checkServerStatus: () => makeRequest(() => axios.get(`${BASE_URL}`)),

  // Admin Routes
  register: (userData) =>
    makeRequest(() =>
      axios.post(`${BASE_URL}/admin/register`, userData, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
    ),

  getAllUsers: () =>
    makeRequest(() =>
      axios.get(`${BASE_URL}/admin/users`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
    ),

  getUserById: (userId) =>
    makeRequest(() =>
      axios.get(`${BASE_URL}/admin/users/${userId}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
    ),

  editUserById: (userId, userData) =>
    makeRequest(() =>
      axios.put(`${BASE_URL}/admin/users/${userId}`, userData, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
    ),

  changePasswordById: (userId, passwordData) =>
    makeRequest(() =>
      axios.put(
        `${BASE_URL}/admin/users/${userId}/change-password`,
        passwordData,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      )
    ),

  deleteUserById: (userId) =>
    makeRequest(() =>
      axios.delete(`${BASE_URL}/admin/users/${userId}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
    ),

  getAllFPR: () =>
    makeRequest(() =>
      axios.get(`${BASE_URL}/admin/password-resets`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
    ),

  getFPRById: (resetId) =>
    makeRequest(() =>
      axios.get(`${BASE_URL}/admin/password-resets/${resetId}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
    ),

  editFPRById: (resetId, resetData) =>
    makeRequest(() =>
      axios.put(`${BASE_URL}/admin/password-resets/${resetId}`, resetData, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
    ),

  deleteFPRById: (resetId) =>
    makeRequest(() =>
      axios.delete(`${BASE_URL}/admin/password-resets/${resetId}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
    ),

  // Authentication Routes
  getUserProfile: () => {
    const token = getToken();
    if (!token) {
      throw new Error('Tidak ada token. Silakan login ulang.');
    }

    return makeRequest(() =>
      axios.get(`${BASE_URL}/users/profile`, {
        headers: { Authorization: `Bearer ${token}` },
      })
    );
  },

  changePassword: (passwordData) => {
    const token = getToken();
    if (!token) {
      throw new Error('Tidak ada token. Silakan login ulang.');
    }

    return makeRequest(() =>
      axios.put(`${BASE_URL}/users/change-password`, passwordData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
    );
  },

  updateUserProfile: (profileData) => {
    const token = getToken();
    if (!token) {
      throw new Error('Tidak ada token. Silakan login ulang.');
    }

    return makeRequest(() =>
      axios.put(`${BASE_URL}/users/profile`, profileData, {
        headers: { Authorization: `Bearer ${token}` },
      })
    );
  },

  deleteUserAccount: () => {
    const token = getToken();
    if (!token) {
      throw new Error('Tidak ada token. Silakan login ulang.');
    }

    return makeRequest(() =>
      axios.delete(`${BASE_URL}/users/account`, {
        headers: { Authorization: `Bearer ${token}` },
      })
    ).then((response) => {
      localStorage.removeItem('tokenData');
      return response;
    });
  },

  // User Routes
  login: async (credentials) => {
    try {
      const response = await axios.post(`${BASE_URL}/auth/login`, credentials, {
        validateStatus: function (status) {
          return (status >= 200 && status < 300) || status === 401;
        },
      });

      if (response.status === 200) {
        const tokenData = {
          token: response.data.token,
          user: response.data.user,
          createdAt: Date.now(),
        };

        localStorage.setItem('tokenData', JSON.stringify(tokenData));
        return response.data;
      } else if (response.status === 401) {
        throw new Error('Username atau password salah');
      }

      throw new Error('Login gagal');
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message || 'Login gagal');
      } else if (error.request) {
        throw new Error('Tidak ada respons dari server');
      } else {
        throw new Error(error.message || 'Terjadi kesalahan');
      }
    }
  },

  sendResetCode: (email) => {
    return makeRequest(() =>
      axios.post(`${BASE_URL}/auth/reset-password/send-code`, { email })
    );
  },

  verifyResetCode: async (email, resetCode) => {
    const response = await makeRequest(() =>
      axios.post(`${BASE_URL}/auth/reset-password/verify-code`, {
        email,
        resetCode,
      })
    );
    return response;
  },

  resetPassword: (newPassword, token) => {
    return makeRequest(() =>
      axios.post(`${BASE_URL}/auth/reset-password`, { newPassword, token })
    );
  },

  logout: () => {
    localStorage.removeItem('tokenData');
    window.location.href = '/login';
  },

  isAuthenticated: () => {
    const token = getToken();
    return token !== null;
  },
};

// Inisialisasi interceptors saat modul di-import
apiService.init();

export default apiService;
