// src/ProtectedRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Pastikan menginstall jwt-decode
import { toast } from 'react-toastify'; // Impor toast

// Utility function untuk membuat URL login
const createLoginUrl = (path) => {
  const encodedPath = encodeURIComponent(path);
  return `/login?redirect=${encodedPath}`;
};

const ProtectedRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = () => {
      const tokenData = localStorage.getItem('tokenData');
      if (!tokenData) {
        // Tampilkan notifikasi jika tidak ada token
        toast.error('Anda harus login terlebih dahulu.');
        navigate(createLoginUrl(location.pathname), { replace: true });
        setLoading(false);
        return;
      }

      const { token } = JSON.parse(tokenData);
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000);
        if (decodedToken.exp < currentTime) {
          localStorage.removeItem('tokenData');
          // Tampilkan notifikasi jika token kedaluwarsa
          toast.warning('Sesi Anda telah berakhir. Silakan login kembali.');
          navigate(createLoginUrl(location.pathname), { replace: true });
        } else {
          setIsAuthenticated(true);
        }
      } catch (error) {
        localStorage.removeItem('tokenData');
        // Tampilkan notifikasi jika ada kesalahan dalam decoding token
        toast.error('Terjadi kesalahan saat memverifikasi sesi Anda.');
        navigate(createLoginUrl(location.pathname), { replace: true });
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, [location, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={createLoginUrl(location.pathname)} replace />
  );
};

export default ProtectedRoute;
