import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import firebaseConfig from '../firebaseConfig';
import { Link } from 'react-router'; // Pastikan menggunakan react-router-dom
import { debounce } from 'lodash';

// Inisialisasi Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const SoalList = () => {
  // State Management
  const [soal, setSoal] = useState({
    items: [],
    loading: true,
    error: null,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [soalPerPage] = useState(20);

  // Fetch Soal dengan useCallback untuk optimasi
  const fetchSoal = useCallback(async () => {
    try {
      const soalCollection = collection(db, '494/soal/soal');
      const soalSnapshot = await getDocs(soalCollection);
      const soalList = soalSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setSoal({
        items: soalList,
        loading: false,
        error: null,
      });
    } catch (error) {
      setSoal((prev) => ({
        ...prev,
        loading: false,
        error: 'Gagal mengambil data soal',
      }));
    }
  }, []);

  // Effect untuk fetch data dan set judul
  useEffect(() => {
    document.title = 'Daftar Soal - Ujian Tool';
    fetchSoal();
  }, [fetchSoal]);

  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        setSearchTerm(value);
        setCurrentPage(1);
      }, 300),
    []
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    debouncedSearch(value);
  };

  useEffect(() => {
    // Cleanup debounce pada unmount
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // Memoize filtered soal untuk performa
  const filteredSoal = useMemo(() => {
    return soal.items.filter((item) => {
      const soalText =
        item.soal && item.soal[0] && item.soal[0].soal
          ? item.soal[0].soal.toLowerCase()
          : '';
      const soalId = item.id.toLowerCase();
      return (
        soalText.includes(searchTerm.toLowerCase()) ||
        soalId.includes(searchTerm.toLowerCase())
      );
    });
  }, [soal.items, searchTerm]);

  // Pagination Logic dengan useMemo
  const paginationData = useMemo(() => {
    const indexOfLastSoal = currentPage * soalPerPage;
    const indexOfFirstSoal = indexOfLastSoal - soalPerPage;
    const currentSoal = filteredSoal.slice(indexOfFirstSoal, indexOfLastSoal);
    const totalPages = Math.ceil(filteredSoal.length / soalPerPage);

    return { currentSoal, totalPages };
  }, [filteredSoal, currentPage, soalPerPage]);

  // Fungsi render pagination
  const renderPagination = useMemo(() => {
    const { totalPages } = paginationData;
    const paginationItems = [];
    const maxVisiblePages = 5;
    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxVisiblePages / 2)
    );
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    // Logika rendering pagination sama seperti sebelumnya
    if (startPage > 1) {
      paginationItems.push(
        <li className="page-item" key="first">
          <button className="page-link" onClick={() => setCurrentPage(1)}>
            1
          </button>
        </li>
      );
      if (startPage > 2) {
        paginationItems.push(
          <li className="page-item disabled" key="ellipsis-start">
            <span className="page-link">...</span>
          </li>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <li
          className={`page-item ${currentPage === i ? 'active' : ''}`}
          key={i}
        >
          <button className="page-link" onClick={() => setCurrentPage(i)}>
            {i}
          </button>
        </li>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        paginationItems.push(
          <li className="page-item disabled" key="ellipsis-end">
            <span className="page-link">...</span>
          </li>
        );
      }
      paginationItems.push(
        <li className="page-item" key="last">
          <button
            className="page-link"
            onClick={() => setCurrentPage(totalPages)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    return paginationItems;
  }, [currentPage, paginationData]);

  // Render loading
  if (soal.loading) {
    return (
      <div
        className="container-fluid vh-100 d-flex align-items-center justify-content-center"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 9999,
          background: 'rgba(255, 255, 255, 0.8)',
        }}
      >
        <div className="text-center">
          <div
            className="spinner-grow text-primary"
            role="status"
            style={{
              width: '3rem',
              height: '3rem',
            }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
          <p
            className="mt-3 text-muted"
            style={{
              fontSize: '1rem',
              fontWeight: 500,
            }}
          >
            Sedang mengambil data...
          </p>
        </div>
      </div>
    );
  }

  // Render error
  if (soal.error) {
    return (
      <div className="container mt-5">
        <div className="alert alert-danger" role="alert">
          {soal.error}
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid px-3 px-md-4 py-4">
      <div className="card shadow-sm mb-4">
        <div className="card-header bg-primary text-white d-flex align-items-center">
          <Link to="/" className="btn btn-light me-2 me-md-3">
            <i className="fas fa-arrow-left"></i>
          </Link>
          <h1 className="mb-0 flex-grow-1 fs-5 fs-md-4">Cari Soal Ujian</h1>
        </div>
        <div className="card-body">
          {/* Header Informasi */}
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-3 gap-2">
            <h5 className="mb-0">
              <span className="badge bg-secondary">
                {filteredSoal.length} Mata Pelajaran
              </span>
            </h5>
            <div className="input-group" style={{ maxWidth: '300px' }}>
              <span className="input-group-text bg-light">
                <i className="fas fa-search text-muted"></i>
              </span>
              <input
                type="text"
                placeholder="Cari Soal..."
                className="form-control"
                onChange={handleSearchChange}
              />
            </div>
          </div>

          {/* Daftar Soal */}
          <div className="row g-3">
            {paginationData.currentSoal.length > 0 ? (
              paginationData.currentSoal.map((item) => (
                <div
                  className="col-12 col-sm-6 col-lg-4 col-xl-3"
                  key={item.id}
                >
                  <Link
                    to={`/ambil-soal/${item.id}`}
                    className="card card-hover h-100 text-decoration-none"
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <h5 className="card-title mb-0 fs-6">
                          <i className="fas fa-file-alt me-2 text-primary"></i>
                          Soal ID: {item.id}
                        </h5>
                        <span className="badge bg-primary">
                          {item.soal ? item.soal.length : 0}
                        </span>
                      </div>
                      <p className="card-text text-muted small">
                        <i className="fas fa-info-circle me-2 text-secondary"></i>
                        Klik untuk melihat detail soal
                      </p>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <div className="col-12">
                <div
                  className="alert alert-warning d-flex align-items-center"
                  role="alert"
                >
                  <i className="fas fa-exclamation-triangle me-2"></i>
                  Tidak ada soal yang ditemukan.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Pagination */}
      <div className="card shadow-sm">
        <div className="card-body">
          <nav aria-label="Page navigation">
            <ul className="pagination justify-content-center mb-0 flex-wrap">
              {/* Tombol Previous */}
              <li
                className={`page-item me-1 d-none d-sm-block ${currentPage === 1 ? 'disabled' : ''}`}
              >
                <button
                  className="page-link"
                  onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
                >
                  <i className="fas fa-chevron-left"></i>
                </button>
              </li>

              {/* Tombol pertama */}
              {currentPage > 3 && (
                <>
                  <li className="page-item d-none d-md-block">
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(1)}
                    >
                      1
                    </button>
                  </li>
                  {currentPage > 4 && (
                    <li className="page-item disabled d-none d-md-block">
                      <span className="page-link">...</span>
                    </li>
                  )}
                </>
              )}

              {/* Tombol halaman dinamis */}
              {renderPagination &&
                renderPagination.filter((_, index) => {
                  // Batasi jumlah tombol yang ditampilkan
                  const totalButtons = window.innerWidth < 576 ? 3 : 5;
                  const start = Math.max(
                    0,
                    Math.min(
                      renderPagination.length - totalButtons,
                      Math.max(
                        0,
                        renderPagination.findIndex(
                          (item) => item.key === `${currentPage}`
                        ) - Math.floor(totalButtons / 2)
                      )
                    )
                  );
                  return index >= start && index < start + totalButtons;
                })}

              {/* Tombol terakhir */}
              {currentPage < paginationData.totalPages - 2 && (
                <>
                  {currentPage < paginationData.totalPages - 3 && (
                    <li className="page-item disabled d-none d-md-block">
                      <span className="page-link">...</span>
                    </li>
                  )}
                  <li className="page-item d-none d-md-block">
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(paginationData.totalPages)}
                    >
                      {paginationData.totalPages}
                    </button>
                  </li>
                </>
              )}

              {/* Tombol Next */}
              <li
                className={`page-item ms-1 d-none d-sm-block ${currentPage === paginationData.totalPages ? 'disabled' : ''}`}
              >
                <button
                  className="page-link"
                  onClick={() =>
                    setCurrentPage(
                      Math.min(paginationData.totalPages, currentPage + 1)
                    )
                  }
                >
                  <i className="fas fa-chevron-right"></i>
                </button>
              </li>
            </ul>
          </nav>

          {/* Navigasi untuk layar kecil */}
          <div className="d-sm-none d-flex justify-content-between align-items-center mt-2">
            <button
              className={`btn btn-outline-secondary ${currentPage === 1 ? 'disabled' : ''}`}
              onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
            >
              <i className="fas fa-chevron-left me-1"></i>Prev
            </button>
            <span className="text-muted small">
              Halaman {currentPage} dari {paginationData.totalPages}
            </span>
            <button
              className={`btn btn-outline-secondary ${currentPage === paginationData.totalPages ? 'disabled' : ''}`}
              onClick={() =>
                setCurrentPage(
                  Math.min(paginationData.totalPages, currentPage + 1)
                )
              }
            >
              Next<i className="fas fa-chevron-right ms-1"></i>
            </button>
          </div>

          {/* Indikator halaman untuk semua layar */}
          <div className="text-center mt-2 text-muted small d-none d-sm-block">
            Halaman {currentPage} dari {paginationData.totalPages}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SoalList);
