const firebaseConfig = {
  apiKey: 'AIzaSyB5y5sCkat-gX8wUxTcVjPJ5hYqtWzblJk',
  authDomain: 'cbt-04.firebaseapp.com',
  projectId: 'cbt-04',
  storageBucket: 'cbt-04.appspot.com',
  messagingSenderId: '785666862583',
  appId: '1:785666862583:web:8199ee81ea731b5a4892f2',
  measurementId: 'G-K06M22HNCS',
};

// Simpan objek dalam variabel sebelum mengekspornya
const config = firebaseConfig;

export default config; // Ini tidak akan menyebabkan peringatan
