import React, { useEffect, useState } from 'react';
import apiService from '../../services/apiService'; // Pastikan path ini sesuai
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await apiService.getAllUsers();
      setUsers(response);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (userId) => {
    if (window.confirm('Apakah Anda yakin ingin menghapus pengguna ini?')) {
      try {
        await apiService.deleteUserById(userId);
        toast.success('Pengguna berhasil dihapus');
        fetchUsers(); // Memperbarui daftar pengguna setelah penghapusan
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2 className="text-primary">Daftar Pengguna</h2>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>No</th> {/* Kolom untuk nomor urut */}
            <th>Username</th>
            <th>Email</th>
            <th>Tanggal Dibuat</th>
            <th>Login Terakhir</th> {/* Kolom untuk Login Terakhir */}
            <th>Admin</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          {users.map(
            (
              user,
              index // Menambahkan index sebagai nomor urut
            ) => (
              <tr key={user.id}>
                <td>{index + 1}</td> {/* Menampilkan nomor urut */}
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>{new Date(user.createdAt).toLocaleString()}</td>{' '}
                {/* Menampilkan Tanggal Dibuat dengan waktu */}
                <td>
                  {user.lastLogin
                    ? new Date(user.lastLogin).toLocaleString()
                    : '-'}
                </td>{' '}
                {/* Menampilkan Login Terakhir dengan waktu */}
                <td>{user.isAdmin ? 'Ya' : 'Tidak'}</td>
                <td>
                  <Link
                    to={`/admin/users/${user.id}`}
                    className="btn btn-info btn-sm"
                  >
                    Detail
                  </Link>
                  <Link
                    to={`/admin/users/edit/${user.id}`}
                    className="btn btn-warning btn-sm ms-2"
                  >
                    Edit
                  </Link>
                  <button
                    className="btn btn-danger btn-sm ms-2"
                    onClick={() => handleDelete(user.id)}
                  >
                    Hapus
                  </button>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UserList;
