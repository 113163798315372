import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'; // Pastikan Anda menggunakan pustaka toast yang sesuai
import apiService from '../services/apiService'; // Sesuaikan dengan jalur yang benar ke apiService Anda

const Profile = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState({
    username: '',
    email: '',
    firstName: '',
    lastName: '',
  });
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const [loading, setLoading] = useState(true);
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [isDeletingAccount, setIsDeletingAccount] = useState(false);

  const [updatingProfileText, setUpdatingProfileText] =
    useState('Perbarui Profil');
  const [changingPasswordText, setChangingPasswordText] =
    useState('Ubah Password');
  const [deletingAccountText, setDeletingAccountText] = useState(
    'Hapus Akun Permanen'
  );

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const userData = await apiService.getUserProfile();
        setProfile(userData);
        setLoading(false);
      } catch (error) {
        toast.error('Gagal memuat profil');
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfile((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const updateProfile = async (e) => {
    e.preventDefault();
    setIsUpdatingProfile(true); // Set loading state
    setUpdatingProfileText('Memperbarui...');
    try {
      const updatedProfile = await apiService.updateUserProfile(profile);
      setProfile(updatedProfile);
      toast.success('Perbarui Profil');
      setUpdatingProfileText('');
    } catch (error) {
      toast.error('Gagal memperbarui profil');
    } finally {
      setIsUpdatingProfile(false); // Reset loading state
    }
    toast.success('Perbarui Profil');
  };

  const changePassword = async (e) => {
    e.preventDefault();
    setIsChangingPassword(true); // Set loading state
    setChangingPasswordText('Mengubah...');

    if (passwordData.newPassword !== passwordData.confirmNewPassword) {
      toast.error('Konfirmasi password tidak cocok');
      setIsChangingPassword(false); // Reset loading state
      return;
    }

    if (passwordData.newPassword.length < 8) {
      toast.error('Password minimal 8 karakter');
      setIsChangingPassword(false); // Reset loading state
      return;
    }

    try {
      await apiService.changePassword(passwordData);
      toast.success('Password berhasil diubah');

      // Logout after successful password change
      await apiService.logout();

      // Redirect to home page
      window.location.href = '/'; // Ganti dengan path home yang sesuai jika perlu
      toast.info('Silahkan Login Kembali');

      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      });
    } catch (error) {
      toast.error('Gagal mengubah password');
    } finally {
      setIsChangingPassword(false); // Reset loading state
    }
    setChangingPasswordText('Ubah Password');
  };

  const deleteAccount = async () => {
    const konfirmasi = window.confirm(
      'Anda yakin ingin menghapus akun? Tindakan ini tidak dapat dibatalkan.'
    );

    if (konfirmasi) {
      setIsDeletingAccount(true); // Set loading state
      setDeletingAccountText('Menghapus...');
      try {
        await apiService.deleteUserAccount();
        toast.success('Akun berhasil dihapus');
        apiService.logout();
        navigate('/login');
      } catch (error) {
        toast.error('Gagal menghapus akun');
      } finally {
        setIsDeletingAccount(false); // Reset loading state
      }
    }
    setDeletingAccountText('Hapus Akun Permanen');
  };

  if (loading) {
    return (
      <div className="container-fluid vh-100 d-flex align-items-center justify-content-center bg-light">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className="container-fluid min-vh-100 d-flex align-items-center justify-content-center bg-light py-4"
      style={{ overflowY: 'auto' }}
    >
      <div className="row w-100 justify-content-center">
        <div className="col-12 col-md-10 col-lg-6 col-xl-5">
          <div
            className="card shadow-lg border-0 rounded-4 p-4 p-md-5"
            style={{
              background: 'linear-gradient(135deg, #f6f8f9 0%, #e5ebee 100%)',
              transform: 'pers pective(1000px) rotateX(2deg)',
            }}
          >
            <div className="position-absolute top-0 start-0 m-3">
              <button
                onClick={() => navigate(-1)}
                className="btn btn-light me-2 me-md-3"
              >
                <i className="fas fa-arrow-left"></i>
              </button>
            </div>
            <div className="card-body">
              <h1 className="h3 text-primary text-center mb-4">
                Pengaturan Profil
              </h1>

              {/* Form Profil */}
              <form onSubmit={updateProfile} className="mb-4">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Nama Depan</label>
                    <input
                      type="text"
                      className="form-control"
                      name="firstName"
                      value={profile.firstName}
                      onChange={handleProfileChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Nama Belakang</label>
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      value={profile.lastName}
                      onChange={handleProfileChange}
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="form-label">Username</label>
                  <input
                    type="text"
                    className="form-control"
                    name="username"
                    value={profile.username}
                    onChange={handleProfileChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={profile.email}
                    onChange={handleProfileChange}
                  />
                </div>

                <div className="d-grid">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isUpdatingProfile} // Disable button when updating
                  >
                    {updatingProfileText}
                  </button>
                </div>
              </form>

              {/* Form Ganti Password */}
              <form onSubmit={changePassword} className="mb-4">
                <h2 className="h5 text-primary mb-3">Ganti Password</h2>
                <div className="mb-3">
                  <label className="form-label">Password Saat Ini</label>
                  <input
                    type="password"
                    className="form-control"
                    value={passwordData.currentPassword}
                    onChange={(e) =>
                      setPasswordData({
                        ...passwordData,
                        currentPassword: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Password Baru</label>
                  <input
                    type="password"
                    className="form-control"
                    value={passwordData.newPassword}
                    onChange={(e) =>
                      setPasswordData({
                        ...passwordData,
                        newPassword: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Konfirmasi Password Baru</label>
                  <input
                    type="password"
                    className="form-control"
                    value={passwordData.confirmNewPassword}
                    onChange={(e) =>
                      setPasswordData({
                        ...passwordData,
                        confirmNewPassword: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="d-grid">
                  <button
                    type="submit"
                    className="btn btn-warning"
                    disabled={isChangingPassword} // Disable button when changing password
                  >
                    {changingPasswordText}
                  </button>
                </div>
              </form>

              {/* Hapus Akun */}
              <div className="border-top pt-3">
                <h2 className="h5 text-danger mb-3">Zona Berbahaya</h2>
                <div className="d-grid">
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={deleteAccount}
                    disabled={isDeletingAccount} // Disable button when deleting account
                  >
                    <i className="fas fa-trash-alt me-2"></i>
                    {deletingAccountText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
