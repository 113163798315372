import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import apiService from '../services/apiService';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [resetCode, setResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [token, setToken] = useState(''); // State untuk menyimpan token

  // Handler to send reset code
  const handleSendCode = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await apiService.sendResetCode(email);
      setCodeSent(true);
      toast.success(`Kode reset telah dikirim ke: ${email}!`);
    } catch (error) {
      toast.error(
        'Pengguna tidak ditemukan. Silakan periksa kembali email Anda.'
      );
    } finally {
      setLoading(false);
    }
  };

  // Handler to verify reset code
  const handleVerifyCode = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await apiService.verifyResetCode(email, resetCode);
      if (response && response.token) {
        setToken(response.token); // Simpan token dari respons
        toast.success(response.message);
      } else {
        toast.error('Kode reset tidak valid!');
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message || 'Kode reset tidak valid!');
      } else {
        toast.error('Terjadi kesalahan. Silakan coba lagi.');
      }
    } finally {
      setLoading(false);
    }
  };
  // Handler to reset password
  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error('Password baru dan konfirmasi password tidak cocok!');
      return;
    }

    const passwordStrengthValid = newPassword.length >= 6; // Password must be at least 6 characters
    if (!passwordStrengthValid) {
      toast.error('Password baru harus memiliki minimal 6 karakter!');
      return;
    }

    setLoading(true);
    try {
      await apiService.resetPassword(newPassword, token); // Gunakan token yang telah disimpan
      toast.success('Password baru berhasil diatur!');
      // Reset state
      setEmail('');
      setResetCode('');
      setNewPassword('');
      setConfirmPassword('');
      setCodeSent(false);
      setToken(''); // Reset token
      navigate('/login');
    } catch (error) {
      toast.error('Gagal mereset password. Silakan coba lagi.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid vh-100 d-flex align-items-center justify-content-center bg-light">
      <div className="row w-100 justify-content-center">
        <div className="col-12 col-md-8 col-lg-5">
          <div className="card shadow-lg border-0 rounded-4 p-4 p-md-5">
            <div className="card-body">
              <h1 className="display-5 fw-bold mb-4 text-primary text-center">
                Reset Password
              </h1>

              {!codeSent ? (
                <>
                  <form onSubmit={handleSendCode}>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Masukkan email Anda"
                        required
                        disabled={loading}
                      />
                    </div>
                    <div className="d-grid mb-3">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg"
                        disabled={loading}
                      >
                        {loading ? 'Mengirim...' : 'Kirim Kode Reset'}
                      </button>
                    </div>
                  </form>
                  <div className="d-grid">
                    <button
                      className="btn btn-secondary btn-lg"
                      onClick={() => navigate('/login')}
                    >
                      Kembali ke Login
                    </button>
                  </div>
                </>
              ) : (
                <form onSubmit={handleVerifyCode}>
                  <div className="mb-3">
                    <label htmlFor="resetCode" className="form-label">
                      Kode Reset
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="resetCode"
                      value={resetCode}
                      onChange={(e) => setResetCode(e.target.value)}
                      placeholder="Masukkan kode reset"
                      required
                    />
                  </div>
                  <div className="d-grid mb-3">
                    <button
                      type="submit"
                      className="btn btn-primary btn-lg"
                      disabled={loading}
                    >
                      {loading ? 'Verifikasi...' : 'Verifikasi Kode'}
                    </button>
                  </div>
                </form>
              )}
              {codeSent && (
                <form onSubmit={handleResetPassword}>
                  <div className="mb-3">
                    <label htmlFor="username" className="form-label">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="username"
                      value={email}
                      placeholder="Email Anda"
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="newPassword" className="form-label">
                      Password Baru
                    </label>
                    <input
                      type="password"
                      className="form-control form-control-lg"
                      id="newPassword"
                      value={newPassword}
                      disabled={loading || !token}
                      onChange={(e) => setNewPassword(e.target.value)}
                      placeholder="Masukkan password baru"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="confirmPassword" className="form-label">
                      Konfirmasi Password Baru
                    </label>
                    <input
                      type="password"
                      className="form-control form-control-lg"
                      id="confirmPassword"
                      value={confirmPassword}
                      disabled={loading || !token}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder="Konfirmasi password baru"
                      required
                    />
                  </div>
                  <div className="d-grid">
                    <button
                      type="submit"
                      className="btn btn-primary btn-lg"
                      disabled={loading || !token} // Disable button if loading or token is not set
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Sedang Mengubah...
                        </>
                      ) : (
                        'Ganti Password'
                      )}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
