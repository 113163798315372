// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './components/Home';
import SearchUser from './components/SearchUser';
import SoalList from './components/SoalList';
import SoalDetail from './components/SoalDetail';
import DetailJawaban from './components/JawabanDetail';
import NotFound from './components/NotFound';
import Login from './components/Login';
import UserPanel from './components/UserProfile';
import Profile from './components/Profile';
import ResetPassword from './components/ResetPassword';
import APIStatus from './components/APIStatus';

import ProtectedRoute from './ProtectedRoute';
import AdminRoute from './AdminRoute';

import AdminPanel from './components/admin/AdminPanel';
import AdminDashboard from './components/admin/AdminDashboard';
import UserList from './components/admin/UserList';
import UserDetail from './components/admin/UserDetail';
import UserRegister from './components/admin/UserRegister';
import UserEdit from './components/admin/UserEdit';
import FPRCreate from './components/admin/FPRCreate';
import FPREdit from './components/admin/FPREdit';
import FPRList from './components/admin/FPRList';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  // Inisialisasi apiService di sini jika perlu
  React.useEffect(() => {
    // Anda bisa menginisialisasi apiService di sini jika perlu
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Routes>
        {/* Rute publik */}
        <Route path="/" element={<Home />} />
        <Route path="/api-status" element={<APIStatus />} />
        <Route path="/login" element={<Login />} />

        {/* to do list  */}
        <Route path="/reset-password" element={<ResetPassword />} />

        {/* Rute yang memerlukan autentikasi */}
        <Route element={<ProtectedRoute />}>
          <Route path="/cari-nilai" element={<SearchUser />} />
          <Route path="/soal" element={<SoalList />} />
          <Route path="/ambil-soal/:slug" element={<SoalDetail />} />
          <Route path="/jawaban/:mapel/:userId" element={<DetailJawaban />} />
          <Route path="/user" element={<UserPanel />} />
          <Route path="/profile" element={<Profile />} />

          {/* Rute admin dilindungi oleh AdminRoute */}
          <Route element={<AdminRoute />}>
            <Route path="/admin/*" element={<AdminPanel />}>
              <Route index element={<AdminDashboard />} />
              <Route path="users" element={<UserList />} />
              <Route path="users/:id" element={<UserDetail />} />
              <Route path="users/new" element={<UserRegister />} />
              <Route path="users/edit/:id" element={<UserEdit />} />
              <Route path="fpr" element={<FPRList />} />
              <Route path="fpr/new" element={<FPRCreate />} />
              <Route path="fpr/edit/:resetId" element={<FPREdit />} />
            </Route>
          </Route>
        </Route>

        {/* Rute fallback */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;
