import React, { useEffect } from 'react';
import { Link } from 'react-router'; // Pastikan menggunakan react-router-dom

const NotFound = () => {
  useEffect(() => {
    document.title = 'Halaman tidak ditemukan - Ujian Tool';
  }, []);

  return (
    <div className="container-fluid vh-100 d-flex align-items-center justify-content-center bg-light">
      <div className="row w-100 justify-content-center">
        <div className="col-12 col-md-8 col-lg-6 text-center">
          <div
            className="card shadow-lg border-0 rounded-4 p-4 p-md-5"
            style={{
              background: 'linear-gradient(135deg, #f6f8f9 0%, #e5ebee 100%)',
              transform: 'perspective(1000px) rotateX(2deg)',
            }}
          >
            <div className="card-body">
              <h1
                className="display-1 fw-bold mb-3 text-danger"
                style={{
                  letterSpacing: '-3px',
                  textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
                }}
              >
                404
              </h1>
              <h2
                className="h3 fw-bold mb-3 text-primary"
                style={{
                  letterSpacing: '-1px',
                }}
              >
                Halaman Tidak Ditemukan
              </h2>
              <p className="lead text-muted mb-4">
                Maaf, halaman yang Anda cari tidak tersedia atau telah
                dipindahkan.
              </p>

              <div className="d-flex justify-content-center">
                <Link
                  to="/"
                  className="btn btn-primary btn-lg d-flex align-items-center"
                  style={{
                    transition: 'all 0.3s ease',
                    transform: 'perspective(500px)',
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform =
                      'scale(1.05) perspective(500px)';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform =
                      'scale(1) perspective(500px)';
                  }}
                >
                  <i className="fas fa-home me-2"></i>
                  Kembali ke Beranda
                </Link>
              </div>
            </div>
          </div>

          {/* Tambahan ilustrasi */}
          <div
            className="mt-4 text-muted"
            style={{
              fontSize: '0.8rem',
              opacity: 0.7,
            }}
          >
            <p>
              Mungkin link yang Anda kunjungi salah atau halaman telah dihapus.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
