import React from 'react';

const FPRCreate = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Di sini Anda bisa menambahkan logika untuk mengirim data ke backend
    // Namun, untuk saat ini, kita hanya akan menampilkan pesan
    alert('This feature is under development.');
  };

  return (
    <div>
      <h2>Create Forget Password Request</h2>
      <p>
        This feature is currently under development. Please check back later.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="resetCode" className="form-label">
            Reset Code
          </label>
          <input
            type="text"
            className="form-control"
            id="resetCode"
            name="resetCode"
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Create
        </button>
      </form>
    </div>
  );
};

export default FPRCreate;
