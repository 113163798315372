import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { FaUser, FaKey, FaHome, FaPlusCircle, FaListUl } from 'react-icons/fa';

const AdminPanel = () => {
  const [isUserDropdownOpen, setUserDropdownOpen] = useState(false);
  const [isFPRDropdownOpen, setFPRDropdownOpen] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(true); // State untuk sidebar

  const toggleUserDropdown = () => {
    setUserDropdownOpen(!isUserDropdownOpen);
  };

  const toggleFPRDropdown = () => {
    setFPRDropdownOpen(!isFPRDropdownOpen);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="container-fluid vh-100 d-flex flex-column">
      <div className="row flex-grow-1">
        <nav
          className={`col-md-3 col-lg-2 d-md-block bg-light sidebar border-end p-3 ${isSidebarOpen ? '' : 'd-none d-md-block'}`}
        >
          <button
            className="btn btn-outline-primary d-md-none mb-3"
            onClick={toggleSidebar}
          >
            {isSidebarOpen ? 'Sembunyikan Menu' : 'Tampilkan Menu'}
          </button>
          <Link
            to="/admin"
            className="text-decoration-none text-primary text-center mb-4 d-block"
          >
            <h4 className="fw-bold">Admin Panel</h4>
          </Link>
          <ul className="nav flex-column">
            <li className="nav-item">
              <button
                className="nav-link btn btn-link text-start w-100 d-flex align-items-center"
                onClick={toggleUserDropdown}
                aria-expanded={isUserDropdownOpen}
              >
                <FaUser className="me-2" size={20} /> Pengguna
              </button>
              {isUserDropdownOpen && (
                <ul className="nav flex-column ms-3">
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/users">
                      <FaListUl className="me-2" size={20} /> Daftar Pengguna
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/users/new">
                      <FaPlusCircle className="me-2" size={20} /> Buat Pengguna
                      Baru
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li className="nav-item">
              <button
                className="nav-link btn btn-link text-start w-100 d-flex align-items-center"
                onClick={toggleFPRDropdown}
                aria-expanded={isFPRDropdownOpen}
              >
                <FaKey className="me-2" size={20} /> Forget Password Requests
              </button>
              {isFPRDropdownOpen && (
                <ul className="nav flex-column ms-3">
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/fpr">
                      <FaListUl className="me-2" size={20} /> Daftar FPR
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/admin/fpr/new">
                      <FaPlusCircle className="me-2" size={20} /> Buat FPR Baru
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          </ul>
          <div className="mt-4">
            <Link className="nav-link text-decoration-none" to="/">
              <FaHome className="me-2" size={20} /> Kembali ke Beranda
            </Link>
          </div>
        </nav>

        <main
          className={`col-md-9 col-lg-10 ms-sm-auto px-4 ${isSidebarOpen ? '' : 'ps-0'}`}
        >
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Admin Dashboard</h1>
          </div>
          <div className="content-area">
            <Outlet />
          </div>
        </main>
      </div>
      <footer className="footer mt-auto py-3 bg-light border-top">
        <div className="container text-center">
          <span className="text-muted">
            &copy; {currentYear} Azyqs Security Systems. All rights reserved.
          </span>
        </div>
      </footer>
    </div>
  );
};

export default AdminPanel;
