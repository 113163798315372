import React, { useEffect, useState, useCallback } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from 'firebase/firestore';
import firebaseConfig from '../firebaseConfig';
import { Link, useNavigate } from 'react-router-dom';

// Inisialisasi Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const SearchUser = () => {
  // State untuk menyimpan kunci jawaban per mapel
  const [kunciJawaban, setKunciJawaban] = useState({});

  const [searchState, setSearchState] = useState({
    userId: '',
    results: [],
    error: '',
    isLoading: false,
  });

  const [showScroll, setShowScroll] = useState(false);
  const navigate = useNavigate();

  // Fungsi untuk mengambil data kunci dari Firestore
  const fetchDataKunci = useCallback(async (mapel) => {
    try {
      if (!mapel) return {};

      const kunciRef = doc(db, '494/datakunci/datakunci', mapel);
      const kunciSnap = await getDoc(kunciRef);

      if (kunciSnap.exists()) {
        return kunciSnap.data().kunci || {};
      }
      return {};
    } catch (error) {
      return {};
    }
  }, []);

  // Efek untuk mengubah judul halaman
  useEffect(() => {
    document.title = 'Cari Nilai - Ujian Tool';
  }, []);

  // Fungsi scroll to top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Hook untuk mengatur visibilitas tombol scroll
  const useScrollToTop = () => {
    const handleScroll = () => {
      setShowScroll(window.scrollY > 300);
    };

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    return showScroll;
  };

  // Komponen tombol scroll to top
  const ScrollToTopButton = () => {
    const showScroll = useScrollToTop();

    return showScroll ? (
      <button
        className="btn btn-primary back-to-top"
        onClick={scrollToTop}
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          borderRadius: '50%',
          width: '50px',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}
      >
        <i className="fas fa-chevron-up"></i>
      </button>
    ) : null;
  };

  // Fungsi pencarian
  const handleSearch = useCallback(async () => {
    // Validasi input user ID
    if (!searchState.userId.trim()) {
      setSearchState((prev) => ({
        ...prev,
        error: 'User ID tidak boleh kosong.',
        results: [],
        isLoading: false,
      }));
      return;
    }

    // Reset state sebelum pencarian
    setSearchState((prev) => ({
      ...prev,
      isLoading: true,
      error: '',
      results: [],
    }));

    try {
      // Query Firestore untuk mencari dokumen
      const subcollectionRef = collection(db, '494/datasiswa/datasiswa');
      const q = query(
        subcollectionRef,
        where('user', '==', searchState.userId)
      );

      const querySnapshot = await getDocs(q);

      // Penanganan jika data tidak ditemukan
      if (querySnapshot.empty) {
        setSearchState((prev) => ({
          ...prev,
          error: 'Data Tidak ditemukan.',
          isLoading: false,
        }));
        return;
      }

      // Ambil data dokumen dengan kunci untuk setiap mapel
      const documentsPromises = querySnapshot.docs.map(async (document) => {
        const docData = { documentId: document.id, ...document.data() };

        // Ambil data kunci untuk mapel spesifik
        if (docData.mapel) {
          const kunciData = await fetchDataKunci(docData.mapel);
          setKunciJawaban((prev) => ({
            ...prev,
            [docData.mapel]: kunciData,
          }));
        }

        return docData;
      });

      const documents = await Promise.all(documentsPromises);

      // Update state dengan hasil pencarian
      setSearchState((prev) => ({
        ...prev,
        results: documents,
        isLoading: false,
      }));
    } catch (error) {
      // Penanganan error
      setSearchState((prev) => ({
        ...prev,
        error: `Error searching documents: ${error.message}`,
        isLoading: false,
      }));
    }
  }, [searchState.userId, fetchDataKunci]);

  // Parsing jawaban
  const parseJawaban = useCallback((jawaban) => {
    try {
      const jawabanObj =
        typeof jawaban === 'string' ? JSON.parse(jawaban) : jawaban;
      const { id, server, ...cleanJawaban } = jawabanObj;
      return cleanJawaban;
    } catch (error) {
      return {};
    }
  }, []);

  // Render Jawaban
  const renderJawaban = useCallback(
    (jawaban, doc) => {
      const parsedJawaban = parseJawaban(jawaban);
      const jawabanEntries = Object.entries(parsedJawaban);

      // Ambil kunci jawaban untuk mapel spesifik
      const kunciJawabanMapel = kunciJawaban[doc.mapel] || {};

      // Hitung jumlah jawaban benar dan salah
      let jumlahBenar = 0;
      let jumlahSalah = 0;

      jawabanEntries.forEach(([nomor, jawab]) => {
        const kunciJawaban = kunciJawabanMapel[nomor];
        if (kunciJawaban) {
          if (jawab === kunciJawaban) {
            jumlahBenar++;
          } else {
            jumlahSalah++;
          }
        }
      });

      return (
        <div className="card mt-4 shadow-sm">
          <div className="card-header bg-primary text-white d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center py-2 px-3">
            <div className="d-flex align-items-center mb-2 mb-sm-0">
              <i className="fas fa-list-alt me-2"></i>
              <h3 className="mb-0 fs-5 fs-sm-4">Jawaban</h3>
            </div>
            <div className="d-flex align-items-center">
              <span className="badge bg-light text-primary me-2">
                Total: {jawabanEntries.length}
              </span>
              <span className="badge bg-success me-2">
                Benar: {jumlahBenar}
              </span>
              <span className="badge bg-danger me-2">Salah: {jumlahSalah}</span>
              <Link
                to={`/jawaban/${doc.mapel || 'N/A'}/${searchState.userId || 'N/A'}`}
                className="badge bg-light text-primary me-2 text-decoration-none"
              >
                <i className="fas fa-eye me-1"></i>
                <span className="d-none d-sm-inline">Detail Jawaban</span>
                <span className="d-inline d-sm-none">Detail</span>
              </Link>
            </div>
          </div>
          <div
            className="card-body jawaban-container"
            style={{
              maxHeight: '450px',
              overflowY: 'auto',
              overflowX: 'hidden',
              backgroundColor: '#f8f9fa',
              borderRadius: '8px',
            }}
          >
            <div className="row g-2 g-sm-3 g-md-3 g-lg-4 justify-content-center">
              {jawabanEntries.map(([nomor, jawab]) => {
                const kunciJawaban = kunciJawabanMapel[nomor];
                const isBenar = jawab === kunciJawaban;

                return (
                  <div
                    key={nomor}
                    className="col-6 col-sm-4 col-md-3 col-lg-2 text-center"
                  >
                    <div
                      className={`card h-100 jawaban-item border-0 shadow-sm ${isBenar ? 'bg-success text-white' : 'bg-danger text-white'}`}
                      style={{
                        transition: 'all 0.3s ease',
                        cursor: 'pointer',
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.transform = 'scale(1.05)';
                        e.currentTarget.style.boxShadow =
                          '0 4px 6px rgba(0,0,0,0.1)';
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.transform = 'scale(1)';
                        e.currentTarget.style.boxShadow =
                          '0 2px 4px rgba(0,0,0,0.05)';
                      }}
                    >
                      <div className="card-body d-flex flex-column justify-content-center p-2 p-sm-3">
                        <div
                          className="rounded-circle mb-2 mx-auto"
                          style={{
                            width: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: 'bold',
                            fontSize: '1rem',
                          }}
                        >
                          {nomor}
                        </div>
                        <p
                          className="mb-0 text-truncate small"
                          style={{
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {jawab || 'Tidak ada'}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {jawabanEntries.length > 12 && (
            <div
              className="card-footer text-center text-muted d-flex justify-content-center align-items-center py-2"
              style={{
                fontSize: '0.8rem',
                backgroundColor: '#f1f3f5',
              }}
            >
              <div className="d-flex align-items-center">
                <i className="fas fa-info-circle me-1 text-primary"></i>
                <span className="d-none d-sm-inline">
                  Gulir untuk melihat lebih banyak jawaban
                </span>
                <span className="d-inline d-sm-none">Lihat lebih banyak</span>
                <i className="fas fa-chevron-down ms-1 text-primary"></i>
              </div>
            </div>
          )}
        </div>
      );
    },
    [parseJawaban, kunciJawaban, searchState.userId]
  );

  // Render Utama Komponen
  return (
    <div className="container-fluid px-3 px-md-4 py-4">
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 col-lg-8">
          {/* Header dan Navigasi */}
          <div className="card shadow-sm mb-4">
            <div className="card-header bg-primary text-white d-flex align-items-center">
              <button
                onClick={() => navigate(-1)}
                className="btn btn-light me-2 me-md-3"
              >
                <i className="fas fa-arrow-left"></i>
              </button>
              <h1 className="mb-0 flex-grow-1 fs-5 fs-md-4">Cari Pengguna</h1>
            </div>
            <div className="card-body">
              {/* Input Pencarian */}
              <div className="input-group mb-3">
                <span className="input-group-text bg-light">
                  <i className="fas fa-search text-muted"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  value={searchState.userId}
                  onChange={(e) =>
                    setSearchState((prev) => ({
                      ...prev,
                      userId: e.target.value,
                    }))
                  }
                  placeholder="Masukkan User ID"
                />
                <button
                  className="btn btn-primary d-flex align-items-center"
                  onClick={handleSearch}
                  disabled={searchState.isLoading}
                >
                  {searchState.isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Mencari...
                    </>
                  ) : (
                    'Cari'
                  )}
                </button>
              </div>

              {/* Tampilan Error */}
              {searchState.error && (
                <div
                  className="alert alert-danger d-flex align-items-center small"
                  role="alert"
                >
                  <i className="fas fa-exclamation-triangle me-2"></i>
                  {searchState.error}
                </div>
              )}
            </div>
          </div>

          {/* Render Hasil Pencarian */}
          {searchState.results.map((doc) => (
            <div key={doc.documentId} className="card mb-3 shadow-sm">
              <div className="card-header bg-light d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center">
                <h2 className="mb-2 mb-sm-                                0 fs-5">
                  Hasil Ujian
                </h2>
                <span className="badge bg-primary">
                  <i className="fas fa-file-alt me-2"></i>
                  {doc.mapel || 'Mata Pelajaran'}
                </span>
              </div>
              <div className="card-body">
                {/* Informasi Dasar */}
                <div className="row g-2 g-md-3">
                  {[
                    {
                      icon: 'user',
                      label: 'Nama',
                      value: doc.nama || 'Tidak ada nama',
                    },
                    {
                      icon: 'graduation-cap',
                      label: 'Kelas',
                      value: doc.nik || 'Tidak tersedia',
                    },
                    {
                      icon: 'door-open',
                      label: 'Ruang Ujian',
                      value: doc.nik2 || 'Tidak tersedia',
                    },
                    {
                      icon: 'chart-line',
                      label: 'Nilai',
                      value: doc.nilai ?? 'Tidak tersedia',
                    },
                  ].map((item, index) => (
                    <div key={index} className="col-6 col-md-3">
                      <div className="bg-light p-2 p-md-3 rounded h-100">
                        <div className="d-flex align-items-center mb-1">
                          <i
                            className={`fas fa-${item.icon} me-2 text-primary`}
                          ></i>
                          <strong className="small">{item.label}</strong>
                        </div>
                        <p className="mb-0 text-muted small text-truncate">
                          {item.value}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Status dan Waktu */}
                <div className="row g-2 g-md-3 mt-2">
                  <div className="col-12 col-md-6">
                    <div className="bg-light p-2 p-md-3 rounded">
                      <div className="d-flex align-items-center mb-1">
                        <i className="fas fa-clock me-2 text-primary"></i>
                        <strong className="small">Waktu Selesai</strong>
                      </div>
                      <p className="mb-0 text-muted small">
                        {doc.waktuSelesai
                          ? new Date(doc.waktuSelesai).toLocaleString('id-ID', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              timeZone: 'Asia/Jakarta',
                            })
                          : 'Tidak tersedia'}
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="bg-light p-2 p-md-3 rounded">
                      <div className="d-flex align-items-center mb-1">
                        <i className="fas fa-hourglass-half me-2 text-primary"></i>
                        <strong className="small">Sisa Waktu</strong>
                      </div>
                      <p className="mb-0 text-muted small">
                        {doc.sisawaktu !== undefined
                          ? `${doc.sisawaktu} Menit`
                          : 'Tidak tersedia'}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Status */}
                <div className="row mt-2">
                  <div className="col-12">
                    <div
                      className="alert alert-info d-flex align-items-center small"
                      role="alert"
                    >
                      <i className="fas fa-info-circle me-2"></i>
                      Status: {doc.status === 1 ? 'Terkirim' : 'Tidak Tersedia'}
                    </div>
                  </div>
                </div>

                {/* Render Jawaban */}
                {doc.jawaban && renderJawaban(doc.jawaban, doc)}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Tombol Scroll To Top */}
      <ScrollToTopButton />
    </div>
  );
};

// Ekspor Komponen dengan Memoization
export default React.memo(SearchUser);
