// src/AdminRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';

const AdminRoute = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdmin = () => {
      const tokenData = localStorage.getItem('tokenData');
      if (!tokenData) {
        // Tidak ada token, redirect ke login
        setLoading(false);
        return;
      }

      const { token } = JSON.parse(tokenData);
      try {
        const decodedToken = jwtDecode(token);
        setIsAdmin(decodedToken.isAdmin);
      } catch (error) {
        // Jika ada kesalahan dalam decoding token, redirect ke login
        setLoading(false);
        return;
      } finally {
        setLoading(false);
      }
    };

    checkAdmin();
  }, [location]);

  // Jika loading, tampilkan loading indicator
  if (loading) {
    return <div>Loading...</div>;
  }

  // Jika bukan admin, tampilkan notifikasi dan redirect
  if (!isAdmin) {
    toast.error('Anda tidak memiliki izin untuk mengakses halaman ini.');
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default AdminRoute;
